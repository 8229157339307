import React from 'react';
import { Link } from 'react-router-dom';

import FooterLogo from "../../assets/shared/desktop/court_signage-white.png";
import FacebookLogo from "../../assets/shared/desktop/icon-facebook.svg";
import YoutubeLogo from "../../assets/shared/desktop/icon-youtube.svg";
import TwitterLogo from "../../assets/shared/desktop/icon-twitter.svg";
import PinterestLogo from "../../assets/shared/desktop/icon-pinterest.svg";
import InstagramLogo from "../../assets/shared/desktop/icon-instagram.svg"

const Footer = () => {
  return ( 
    <footer className="footer">
      <div className="footer-container container">
        <div className="footer-header">
          <Link to="/">
            <img className="logo" src={FooterLogo} alt="Designo" />
          </Link>
          <div className="footer-header-link">
            <Link to="/about" className="li_nk">ABOUT</Link>
            <Link to="/system" className="li_nk">SYSTEM</Link>
            <Link to="/pricing" className="li_nk">PRICING</Link>
            <Link to="/contact" className="li_nk">CONTACT</Link>
          </div>
        </div>
        <div className="footer-border"></div>
        <div className="footer-content">
          <div className="footer-content-right">
            <p>Court Signage LLC</p>
            <p>San Francisco, California</p>
            <p>© 2025</p>
          </div>
          <div className="footer-content-link">
            <img src={FacebookLogo} alt="Facebook" />
            <img src={YoutubeLogo} alt="Youtube" />
            <img src={TwitterLogo} alt="Twitter" />
            <img src={PinterestLogo} alt="Pinterest" />
            <img src={InstagramLogo} alt="Instagram" />
          </div>
        </div>
      </div>
    </footer>
  );
}
 
export default Footer;
